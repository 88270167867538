<template>
  <!-- Informations générales communes à toutes les pages -->
  <div class="tabs-material-subtitle no-padding-bottom">
    <Container>
      <h2>Preuves de destruction</h2>
    </Container>
  </div>

  <div class="variables-etudiees-subview">
    <Section>
        <List
          :api="list.api"
          :header="list.headers"
          :items="list.col"
          :item-key="list.key"
          disableCheckbox
        >
          <template v-slot:media="{ item }">
            {{ item?.media?.nom }}
          </template>
          <template v-slot:actions="{ item }">
            <Btn
              icon="visibility"
              default
              round
              grow
              @click="openModal('destructionProof', item?.media)"
            />
          </template>
        </List>
    </Section>
  </div>

  <Modal
    title="Voir la preuve de destruction"
    :active="modal.destructionProof"
    :data="modalData"
    @modal-close="modal.destructionProof = false"
  >
    <template v-slot:modal-body="{ data }">
      <ImageToken
        :image="data"
        :alt="data?.nom"
        defaultImage
        size="full"
      />
    </template>
    <template v-slot:modal-footer>
      <Btn text="Fermer" @click="modal.destructionProof = false" />
    </template>
  </Modal>
</template>

<script>
import Btn from '@/components/base/Btn.vue'
import List from '@/components/list/List.vue'
import Section from '@/components/layout/Section.vue'
import Container from '@/components/layout/Container.vue'
import Modal from '@/components/layout/Modal.vue'
import ImageToken from '@/components/base/ImageToken.vue'

export default {
  name: 'DestructionProofSubview',
  components: {
    ImageToken,
    Modal,
    Btn,
    List,
    Section,
    Container,
  },

  props: {
    pageTitle: {
      type: String,
    },
    task: {
      type: Object,
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
  },

  emits: ['set-editable'],

  data() {
    return {
      list: {
        api: {
          endpoint: null,
          params: {
            sort: null,
            page: null,
            limit: 0,
            filters: null,
          },
        },
        headers: [
          { label: 'Nom' },
        ],
        col: ['media'],
        key: 'id',
      },
      modal: {
        destructionProof: false,
      },
      modalData: null,
    }
  },
  mounted() {
    this.list.api.endpoint = `essai/${this.$route.params.id}/evenement/${this.$route.params.tid}/fiche/destruction/preuve`
  },
  methods: {
    openModal(modal, datas) {
      this.modalData = datas
      this.modal[modal] = true
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
