<template>
  <div
    class="image-token"
    v-if="img !== ''"
    :class="{
      'image-token--sm': size === 'sm',
      'image-token--lg': size === 'lg',
      'image-token--full': size === 'full',
    }"
  >
    <img v-if="img" :src="img" :alt="alt" />
    <template v-if="!img && defaultImage">
      <SvgIcon name="image-regular" class="icon-nopicture" />
    </template>
  </div>
</template>

<script>
import SvgIcon from '@/components/base/SvgIcon.vue'

export default {
  name: 'ImageToken',
  components: {
    SvgIcon,
  },
  props: {
    image: {
      type: [String, Object],
    },
    alt: {
      type: String,
    },
    size: {
      type: String,
    },
    defaultImage: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      img: null,
      loading: false,
    }
  },

  watch: {
    // eslint-disable-next-line func-names
    '$props.image': function () {
      this.getImage()
    },
  },

  mounted() {
    if (this.image && 'jeton' in this.image) {
      this.getImage()
    }
  },

  methods: {
    getImage() {
      this.fileService.displayImage(this.image.jeton).then(
        (response) => {
          this.img = response.data
        },
        (error) => {
          console.log('error', error)
        },
      )
    },
  },
}
</script>

<style lang="scss" scoped>
/* USER AVATAR */

$image-token-size: 12rem;
$image-token-size-small: 8rem;
$image-token-size-large: 18rem;

.image-token {
  padding: 0.2rem;
  border: 1px solid $color-gray-lighter;
  max-width: $image-token-size;
}

.image-token--full {
  max-width: 100%;

  > img {
    display: block;
    @include h-margin(auto);
  }
}

.image-token--sm {
  max-width: $image-token-size-small;
}

.image-token--lg {
  max-width: $image-token-size-large;
}
</style>
